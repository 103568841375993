import apiService from "../service/apiService";

const QUESTION_ENDPOINT = "track_question";
const GET_PAYMENT_METHODS = "get-payment-methods";

const apiRequest = async (
  endpoint,
  method = "GET",
  queryString = "",
  options = {},
  setLoading = () => null
) => {
  try {
    return await apiService.fetchData(
      endpoint,
      method,
      queryString,
      options,
      setLoading
    );
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}`, error);
    throw error;
  }
};

export const questionFetchController = {
  fetchData: async (id) => apiRequest(QUESTION_ENDPOINT, "GET", `id=${id}`),
};

export const getPaymentMethodsGETController = {
  fetchData: async (id) => apiRequest(GET_PAYMENT_METHODS, "GET", `country_id=${id}`),
};
