import "quoter365/dist/index.css";
import "../../../root/tracking/sentryConfig";
import "./assets/css/main.css"; // Import global styles
import "./assets/css/tailwind.css"; // Import global styles
import React from "react";
import Products from "./components/Products";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { LanguageProvider } from "./context/LanguageContext";
import * as Sentry from "@sentry/react";
import { LoaderProvider } from "./context/loaderContext";

export default function Root(props) {
  return (
    <Sentry.ErrorBoundary fallback={"Algo salió mal"}>
      <LanguageProvider>
        <I18nextProvider i18n={i18n}>
          <LoaderProvider>
            <div className="products-container">
              <Products />
            </div>
          </LoaderProvider>
        </I18nextProvider>
      </LanguageProvider>
    </Sentry.ErrorBoundary>
  );
}
