import hamburguer from "./../../assets/img/hamburguer.svg";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Whatsapp from "./../../assets/img/logo_whatsapp.svg";
import PhoneIcon from "./../../assets/img/phone.svg";
import { useTranslation } from "react-i18next";
import PhoneSupportDialog from "./phoneSupporDialog";

export default function SideBar({ whatsapp, geo, lang }) {
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <div className='absolute top-0 right-2 text-right'>
        <>
          <button
            type='button'
            onClick={toggleDrawer(true)}
            className='ml-4 mt-1'
          >
            <img src={hamburguer} />
          </button>
          <Drawer anchor='right' open={state} onClose={toggleDrawer(false)}>
            <Box
              className='bg-[#0371e8] h-screen '
              sx={{ width: 320 }}
              role='presentation'
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <div className='flex justify-end pt-6'>
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon className='text-white ' fontSize='large' />
                </IconButton>
              </div>
              <div className='text-right text-white text-2xl font-medium pt-[100px] space-y-8 pr-4 flex items-end justify-center flex-col'>
                <div className='flex flex-col justify-center space-y-6'>
                  {geo?.country_id == 164 && lang !== "pt-BR" && (
                    <button
                      className='flex justify-end'
                      onClick={openDialog}
                    >
                      <img
                        src={PhoneIcon}
                        alt='ícono teléfono'
                        className='mr-[10px]'
                      />
                      Teléfono
                    </button>
                  )}
                  {geo?.country_id !== 155 && (
                    <a
                      href={`https://wa.me/${whatsapp}`}
                      target='_blank'
                      className='flex justify-end'
                    >
                      <img
                        alt='whatsapp'
                        src={Whatsapp}
                        className='mr-[10px]'
                      />
                      Ventas
                    </a>
                  )}
                </div>
              </div>
            </Box>
          </Drawer>
        </>
      </div>
      <div>{open && <PhoneSupportDialog dialogVisibility={setOpen} />}</div>
    </>
  );
}
