import React, { useEffect, useState } from "react";
import { coveragesFetchController } from "../../dataController/dataController";
import no from "./../../assets/img/no.svg";
import included from "./../../assets/img/included.svg";
import { useTranslation } from "react-i18next";
import ComparativeTableHeader from "../ComparativeTableHeader";
import { useBreakpoint } from "../../context/breakpointContext";
import ModalCoverages from "../ModalCoverages/ModalCoverages";

const ComparativeProductsTable = ({
  data,
  initCoverages,
  productSelected,
  modalCoverages,
  setModalCoverages,
}) => {
  const { t } = useTranslation();
  const initial_props = {
    ids: null,
    names: null,
    coverages_data: null,
    types: null,
  };

  const [comparativeTable, setComparativeTable] = useState(initial_props);
  const { currentBreakpoint } = useBreakpoint();
  const [dataTable, setDataTable] = useState({ ids: [], limit: 1 });
  const _breakpoints = new Map([
    ["xs", 1],
    ["sm", 2],
    ["md", 3],
    ["lg", 3],
    ["xl", 4],
    ["2xl", 4],
  ]);

  const [breakpoint_limit, setBreakpointLimit] = useState(_breakpoints);

  useEffect(() => {
    if (comparativeTable?.coverages_data?.length > 0) {
      coverNav("init", true);
    }
  }, [comparativeTable]);

  useEffect(() => {
    if (data?.list?.length > 0 && initCoverages) {
      processCoverages();
    }
  }, [data, initCoverages]);

  const processCoverages = () => {
    const pre_table_data = {
      ids: [],
      names: [],
      type: [],
      total_price: [],
      installments: [],
      price: [],
      currency: null,
    };

    if (data?.list.length > 0) {
      pre_table_data.currency = data?.list[0]?.currency_symbol;
    }

    if (data?.list.length > 0) {
      data?.list.forEach((product) => {
        pre_table_data.ids.push(product.product_id);
        pre_table_data.names.push(product.name.trim().toLowerCase());
        pre_table_data.type.push(product.type);
        pre_table_data.total_price.push(product.total_price);
        if (product.installments > 1) {
          pre_table_data.installments.push({
            total: product.installments,
            amount: product.installment_amount,
          });
        }
        pre_table_data.price.push({
          total_price_back: product?.total_price_back,
          total_price_promo: product?.total_price_promo,
          total_price_usd: product?.total_price_usd,
          product_id: product?.product_id,
          name: product?.name,
        });
      });

      if (pre_table_data.ids.length > 0) {
        const fetchData = async () => {
          const _coverages = await coveragesFetchController.fetchData(
            pre_table_data.ids.join(",")
          );

          const coverages = _coverages.data;
          const coverages_data = [];
          const collection = [];

          for (let id of Object.keys(coverages)) {
            coverages[id].forEach((e, i) => {
              collection.push({ id: e.id, name: e.name });
            });
          }

          collection.filter((item) => {
            var i = coverages_data.findIndex(
              (x) => x.id === item.id && x.name === item.name
            );
            if (i <= -1) {
              coverages_data.push(item);
            }
            return null;
          });

          let counter = 1;

          for (let id of pre_table_data.ids) {
            coverages_data.map((i) => {
              const ref = coverages[id].find((elem) => elem.id === i.id);

              if (ref) {
                let isImg = ref.price;
                const match = isImg.trim().toLowerCase();

                if (match.includes("no inclu") || match.includes("não inclu")) {
                  isImg = <img className="mx-auto" src={no} />;
                }
                if (
                  match.includes("incl") &&
                  !match.includes("no inclu") &&
                  !match.includes("não incl")
                ) {
                  isImg = <img className="mx-auto" src={included} />;
                }

                i["price" + counter] = isImg;

                // FEATURE COLORED

                const type = pre_table_data.type[counter - 1];
                let classStyle = "";
                if (type === "popular" || type === "recommended") {
                  classStyle = "cellClass-green";
                }
                if (type === "oportunity" || type === "multitrip") {
                  classStyle = "cellClass-violet";
                }

                i["cellClass" + counter] = classStyle;
              }
            });
            counter++;
          }

          setComparativeTable({
            ids: pre_table_data.ids,
            names: pre_table_data.names,
            coverages_data: coverages_data,
            types: pre_table_data.type,
            total_price: pre_table_data.total_price,
            installments: pre_table_data.installments,
            price: pre_table_data.price,
            currency: pre_table_data.currency,
          });
        };

        fetchData();
      }
    } else {
    }
  };

  useEffect(() => {
    let limit = breakpoint_limit.get(currentBreakpoint);

    if (data?.list?.length < limit) {
      // limit = products.length;
      limit = data?.list?.length;

    }
    setDataTable({ ...dataTable, limit: limit });
  }, [currentBreakpoint]);

  const getCurrentBreakpointLimit = () => {
    let limit = breakpoint_limit.get(currentBreakpoint);

    if (data?.list?.length < limit) {
      limit = data?.list?.length;
    }
    setDataTable({ ...dataTable, limit: limit });
    return limit;
  };

  const coverNav = (mode, init = false) => {
    if (mode === "init") {
      const ids = Array.from(
        { length: data?.list?.length },
        (_, index) => index + 1
      );

      const limit = getCurrentBreakpointLimit();
      setDataTable({ ids: ids, limit: limit });
    }

    if (mode === "next") {
      const value = [...dataTable.ids];
      value.push(value.shift());
      setDataTable({ ...dataTable, ids: value });
    }

    if (mode === "prev") {
      const value = [...dataTable.ids];
      value.unshift(value.pop());
      setDataTable({ ...dataTable, ids: value });
    }
  };

  const handleSelectProduct = (index = 0) => {
    if (comparativeTable["price"][index]) {
      productSelected(comparativeTable["price"][index]);
    }
  };

  const gotoComparativeTable = () => {
    const elmnt = document.getElementById("comparative-table");
    elmnt?.scrollIntoView({ behavior: "smooth" });
    setModalCoverages({ ...modalCoverages, show: false });
  };
  
  return (
    <>
      {comparativeTable?.coverages_data?.length && modalCoverages?.idx && (
        <ModalCoverages
          gotoComparativeTable={gotoComparativeTable}
          modalCoverages={modalCoverages}
          handleSelectProduct={handleSelectProduct}
          productSelected={productSelected}
          coverages={comparativeTable?.coverages_data}
          close={() => setModalCoverages({ ...modalCoverages, show: false })}
        />
      )}

      {comparativeTable?.ids && (
        <div>
          <div id="comparative-table" className="w-full mt-16 mb-10 lg:mb-0 lg:mt-[25px]">
            <h1 className="text-blue-primary font-bold text-3xl text-center my-10 lg:text-4xl font-inter">
              {t("index.compareTable")}
            </h1>
          </div>
          <ComparativeTableHeader
            navigator={coverNav}
            comparativeTable={comparativeTable}
            products_length={data?.list?.length}
            dataTable={dataTable}
            breakpoint={currentBreakpoint}
            productSelected={productSelected}
          />

          <div className="tc-table flex items-center justify-center">
            <table className="font-sans text-xs font-normal leading-3 text-text-tertiary">
              <tbody>
                {comparativeTable.coverages_data.length > 0 &&
                  comparativeTable.coverages_data.map((cover, index) => {
                    return (
                      <tr key={index}>
                        <td>{cover.name}</td>
                        {Array.from({ length: dataTable.limit }, (_, idx) => {
                          return (
                            <td key={idx}>
                              {cover[`price${dataTable["ids"][idx]}`]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ComparativeProductsTable;
