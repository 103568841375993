import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import es from "dayjs/locale/es";

import ico_location from "./../assets/img/quoter_ico_location.svg";
import ico_calendar from "./../assets/img/quoter_ico_calendar.svg";
import ico_user from "./../assets/img/quoter_ico_user.svg";
import "./quoter.details.component.css";

import { useTranslation } from "react-i18next";

const QuoterDetails = (props) => {
  const { t } = useTranslation();
  const data = props.data || [];
  const breakpoint = props.breakpoint;
  const localeData = require("dayjs/plugin/localeData");
  dayjs.extend(localeData);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });

  const months = dayjs.monthsShort();
  const [dataQuote, setDataQuote] = useState(data);

  useEffect(() => {
    if (Object.keys(data).length > 1) {
      const date_from = dayjs(data.dates.date_from);
      const date_to = dayjs(data.dates.date_to);

      const date_from_label =
        t(`quoterDetails.weekdays.${date_from.format("d")}`).slice(0, 3) +
        ", " +
        date_from.format("DD") +
        " " +
        t(`quoterDetails.months.${date_from.format("M")}`).slice(0, 3) +
        " " +
        date_from.year();

      const date_to_label =
        t(`quoterDetails.weekdays.${date_to.format("d")}`).slice(0, 3) +
        ", " +
        date_to.format("DD") +
        " " +
        t(`quoterDetails.months.${date_to.format("M")}`).slice(0, 3) +
        " " +
        date_to.year();

      setDataQuote({
        ...data,
        date_from_label: date_from_label,
        date_to_label: date_to_label,
      });
    }
  }, [data]);

  return (
    <>
      <div className="bg-blue-quartary w-full flex items-center justify-center">
        <div
          className={
            "section-wrapper w-full bg-[#015fc7] flex-col items-center justify-center " +
            (breakpoint == "xs" ? "max-w-[345px] " : "") +
            (breakpoint == "sm" ? "max-w-[660px] " : "")
          }
        >
          <div className="w-full"></div>
          <div className="quoter-details pt-6 text-[#F7F7F7] font-normal text-base text-center pb-6">
            {Object.keys(dataQuote).length > 1 && (
              <div className="quoter-details flex items-start flex-col justify-start md:flex-row md:justify-center">
                <div>
                  <img src={ico_location} />
                  {t(
                    `quoterDetails.destinations.${dataQuote.destination.code}`
                  )}
                </div>
                <div>
                  <img src={ico_calendar} />
                  {dataQuote.date_from_label}
                </div>
                <div>
                  <img src={ico_calendar} />
                  {dataQuote.date_to_label}
                </div>
                <div>
                  <img src={ico_user} />
                  {dataQuote.insured} {t("quoterDetails.person")}
                  {parseInt(dataQuote.insured) > 1 && <span>s</span>}
                </div>
              </div>
            )}
            <hr />
            <button
              onClick={props.visibility}
              className="w-full underline mx-auto text-white font-semibold text-xl mt-2 cursor-pointer select-none"
            >
              {t("quoterDetails.edit")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoterDetails;
