import React, { useState, useEffect } from "react";

const ComparativeTableHeader = (props) => {
  const { names, types, breakpoint, dataTable, products_length } = props;
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // windowHeight > 150 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };

  const getClassNames = (type) => {
    let _classes = "text-2xl font-semibold font-sans px-8 capitalize ";
    if (type === "recommended" || type === "popular") {
      _classes += "bg-product-popular";
    } else if (type === "oportunity" || type === "multitrip") {
      _classes += "bg-oportunity-bg text-white";

    } else {
      _classes += "text-blue-secondary bg-grey-tertiary";
    }
    return _classes
      ;
  }

  const _navigator = (mode) => {
    props.navigator(mode);
    setStickyClass("sticky-nav");
  };

  return (
    <>
      <div
        className={
          `navbar sticky-nav ${stickyClass}` +
          (breakpoint == "sm" ? " h-[84px] " : "") +
          (breakpoint != "xs" ? " mb-[28px] " : "") +
          (breakpoint != "xl" || breakpoint != "2XL" ? " h-[102px]" : "") +
          (breakpoint == "xl" || breakpoint == "2xl"
            ? " top-[102px] pt-[20px]"
            : "")
        }
      >
        {breakpoint == "xs" && (
          <div className="ct-header flex items-center justify-between">
            <button
              onClick={() => _navigator("prev")}
              type="button"
              className="btn-comparative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M15.5195 6L9.51953 12L15.5195 18"
                  stroke="#2F6DE0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="ct-products">
              {props.names[dataTable["ids"][0] - 1]}
            </div>
            <button
              onClick={() => _navigator("next")}
              type="button"
              className="btn-comparative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M9.51953 18L15.5195 12L9.51953 6"
                  stroke="#2F6DE0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}

        {breakpoint != "xs" && (
          <div className="w-full">
            <div
              id="tc-table"
              className="tc-table desktop flex items-center justify-center"
            >
              <table className="font-sans text-xs font-normal leading-3 text-text-tertiary">
                <tbody>
                  <tr>
                    <td>
                      {dataTable.limit < products_length && (
                        <>
                          <button
                            onClick={() => _navigator("prev")}
                            type="button"
                            className="btn-comparative"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M15.5195 6L9.51953 12L15.5195 18"
                                stroke="#2F6DE0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => _navigator("next")}
                            type="button"
                            className="ml-2 btn-comparative"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.51953 18L15.5195 12L9.51953 6"
                                stroke="#2F6DE0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </>
                      )}
                    </td>
                    {Array.from({ length: dataTable.limit }, (_, idx) => {
                      return (
                        <td
                          key={idx}
                          className={getClassNames(props.types[dataTable["ids"][idx] - 1])}
                        // className={
                        //   "text-2xl font-semibold font-sans px-8 capitalize " +
                        //   (isMulti(props.names[dataTable["ids"][idx] - 1])
                        //     ? "bg-oportunity-bg text-white "
                        //     : "") +
                        //   (!isMulti(props.names[dataTable["ids"][idx] - 1])
                        //     ? "text-blue-secondary bg-grey-tertiary "
                        //     : "")
                        // }
                        >
                          {props.names[dataTable["ids"][idx] - 1]}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ComparativeTableHeader;
