import { useTranslation } from "react-i18next";

const MoreProductsBtn = (props) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex items-center justify-center mt-16">
      <button
        onClick={props.seeMoreProducts}
        type="button"
        className="btn-more-options"
      >
        {t("index.moreOptions")}
      </button>
    </div>
  );
};

export default MoreProductsBtn;
