import React from "react";
import ComparativeTableHeader from "./ComparativeTableHeader";

const ComparativeTable = (props) => {
  const { comparativeTable, dataTable, products, coverNav, breakpoint } = props;

  return (
    <div id="comparative-table" className="w-full mt-16 mb-16 relative">
      <ComparativeTableHeader
        navigator={coverNav}
        names={comparativeTable.names}
        types={comparativeTable.types}
        products_length={products.length}
        dataTable={dataTable}
        breakpoint={breakpoint}
      />
      <div id="tc-table" className="tc-table flex items-center justify-center">
        <table className="font-sans text-xs font-normal leading-3 text-text-tertiary">
          <tbody>
            {comparativeTable.coverages_data.length > 0 &&
              comparativeTable.coverages_data.map((cover, index) => {
                return (
                  <tr key={index}>
                    <td>{cover.name}</td>
                    {Array.from({ length: dataTable.limit }, (_, idx) => {
                      return (
                        <td key={idx} className={cover[`cellClass${dataTable["ids"][idx]}`]}>
                          {cover[`price${dataTable["ids"][idx]}`]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparativeTable;
