import { useTranslation } from "react-i18next";
import arrow_left from "./../assets/img/arrow_left.svg";

const BackComponent = () => {
  const { t } = useTranslation();

  const goBack = () => {
    location.href = "/";
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full flex items-center justify-start max-w-[1310px]">
        <button
          onClick={goBack}
          className="flex items-center justify-start mt-8"
        >
          <img className="mr-2" src={arrow_left} alt={t("index.back")} />
          <span className="text-base font-semibold text-violet-tertiary">
            {t("index.back")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default BackComponent;
