import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";

const CurrencyComponent = (props) => {
  const { t } = useTranslation();
  const { currencies, currentCurrency, Currency } = props;
  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: "white",
        "& .MuiMenuItem-root": {
          paddingTop: 1,
        },
      },
      style: {
        width: 328,
        maxHeight: 400,
        marginTop: "7px",
        borderRadius: "8px",
      },
    },
  };

  return (
    <Fade in={true}>
      <Box style={{ width: "320px" }}>
        <h3 className="text-dark-grey uppercase text-xs font-bold tracking-wider font-sans mb-1">
          {t("index.currencyType")}
        </h3>
        <FormControl variant="outlined" sx={{ width: "100%" }}>
          <Select
            style={{
              fontSize: "16px",
              fontFamily: "Inter",
              fontWeight: "400",
              backgroundColor: "white ",
              color: "#526166",
            }}
            sx={{ width: "100" }}
            displayEmpty
            value={currentCurrency}
            onChange={(value) => Currency("set", true, value.target.value)}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {currencies.list.map((d, idx) => (
              <MenuItem
                className="text-grey-primary"
                key={`currency-id-${idx}`}
                value={d.country_id}
              >
                {d.country_name} - ({d.currency})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Fade>
  );
};

export default CurrencyComponent;
