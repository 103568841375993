import React, { useEffect } from "react";
import banner from "./../assets/img/banner_image.png";
import banner2 from "./../assets/img/banner_image_2.png";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";

import banner_mobile from "./../assets/banners/banner_mobile.svg";
import banner_tablet from "./../assets/banners/banner_tablet.svg";
import banner_desktop from "./../assets/banners/banner_desktop.svg";

import illustration_1 from "./../assets/banners/illustration_1.svg";
import illustration_2 from "./../assets/banners/illustration_2.svg";

const BannerComponent = ({ breakpoint }) => {
  
  const { t } = useTranslation();

  const [promo, setPromo] = React.useState(null);
  const [promoExtraData, setPromoExtraData] = React.useState(null);

  const country_id = window.localStorage.getItem("country_id")?.toString() || "164";

  useEffect(() => {

    const country_id = window.localStorage.getItem("country_id")?.toString() || "164";

    try {
      if (country_id) {

        const url = `https://app.assist-365.com/api/update-banner?country_id=${country_id}`;
        fetch(url)
          .then((response) => response.json())
          .then((promo) => {
            if (promo) {
              const {
                claim,
                condition,
                country_id,
                discount,
                dues,
                name,
                validity,
                fixed,
              } = promo.data;

              const promo_data = {
                claim: claim,
                status: promo.status,
                country_id: country_id,
                discount: discount,
                condition: condition,
                dues: dues?.trim().replace("+",""),
                validity: validity,
                name: name,
                fixed: fixed,
              };
              setPromo(promo_data);

              if (promo_data.status) {

                promo_data.dues ? promo_data.dues = promo_data.dues : promo_data.dues = '';
                promo_data.condition ? promo_data.condition = promo_data.condition : promo_data.condition = '';

                const promo_discount = promo_data.discount;
                const promo_text = `${promo_data.condition} ${promo_data.dues} ${promo_data.validity}`;

                setPromoExtraData(
                  {
                    ...promoExtraData,
                    promo_discount: promo_discount,
                    promo_text: promo_text,
                  }
                );

              }

            } else {
              setPromo(null);
            }

          });
      }
    } catch (error) { }
  }, []);



  return (
    <Fade in={true}>
      {country_id !== "164" ? (<div className="mt-32 sm:my-[120px] mb-[119px] lg:mt-60 leading-6 font-semibold text-xl rounded-lg w-full py-4 px-6 bg-popular-bg text-text-primary flex items-center justify-between max-w-[926px] lg:max-w-[1144px] mx-auto">
        <img src={banner} alt="banner" />
        <span className="ml-4 sm:text-2xl md:text-center lg:ml-0 lg:text-4xl md:max-w-[454px] lg:max-w-[520px]">
          {t("index.bannerText")}
        </span>
        {breakpoint !== "xs" && (
          <img alt="banner" style={{ width: "90px" }} src={banner2} />
        )}
      </div>) : (
        <div className="mt-32 mb-[119px] w-full flex items-center justify-center">
          {/* <img className="mx-auto" src={banner_src} /> */}
          <div className={
            "bg-popular-bg text-xl md:text-2xl lg:text-[40px] rounded-lg flex items-center justify-center lg:justify-between w-full font-semibold h-[120px] lg:h-[200px] overflow-hidden " +
            (breakpoint === "xs" ? "max-w-[359px] " : "") +
            (breakpoint === "sm" ? "max-w-[396px] " : "") +
            (breakpoint === "md" ? "max-w-[460px] " : "") +
            (breakpoint === "lg" || breakpoint === "xl" || breakpoint === "2xl" ? "max-w-[1024px] " : "")
          }
          >
            <div className="h-full relative flex items-center justify-center">
              {breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" ? (<img className={breakpoint === "md" ? "w-[90px]" : ""} src={illustration_2} />) : (
                <div className="bg-violet-banner banner-oval h-full text-white text-4xl font-semibold flex items-center justify-center px-5">
                  ¡Aprovecha!
                </div>
              )}
            </div>
            <div className="flex flex-col lg:flex-row text-text-primary font-titillium text-center p-4 leading-6 lg:leading-10">
              <div className="block lg:hidden text-white text-2xl">¡Aprovecha!</div>
              <div className="lg:flex lg:flex-row lg:items-center">
                {(breakpoint === "lg" || breakpoint === "xl" || breakpoint === "2xl") && <img className="ml-4" src={illustration_2} />}
                <span>{promo?.dues}</span>
              </div>
              <div className="lg:flex lg:flex-row lg:items-center">
                {(breakpoint === "lg" || breakpoint === "xl" || breakpoint === "2xl") && <img src={illustration_1} />}
                <span>En todos los planes</span>
              </div>
            </div>
            <div className="hidden md:block lg:hidden">
              <img className={(breakpoint === "md") ? "ml-3 w-[80px]" : ""} src={illustration_1} /></div>
          </div>
        </div>
      )
      }
    </Fade>
  );
};

export default BannerComponent;
