import React from "react";

const QuoterWrapper = (props) => {
  const breakpoint = props.breakpoint;

  return (
    <div className="flex flex-col items-center justify-center bg-[#015fc7] w-full">
      <div
        className={
          "section-wrapper w-full flex items-center justify-center " +
          (breakpoint == "xs" ? "max-w-[345px] " : "") +
          (breakpoint == "sm" ? "max-w-[660px] " : "") +
          (breakpoint == "md" ? "max-w-[768px] " : "") +
          (breakpoint == "lg" ? "max-w-[1056px] " : "")
        }
      >
        <div id="pseudo-accordion" className="pseudo-accordion">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default QuoterWrapper;
