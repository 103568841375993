import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          back: "Voltar",
          title: "Escolha o plano que se adequa às suas necessidades",
          travelDetail: "Detalhe da sua viagem",
          currencyType: "Tipo de moeda",
          moreOptions: "Ver mais opções",
          otherOptions: "Ver mais opções",
          bannerText:
            "Cancelamento gratuito com 24 horas de antecedência em todos os planos",
          compareTable: "Compare planos de ponta a ponta",
        },
        productCard: {
          installments: "x sem juros de",
          totalPrice: "Preço final",
          viewCoverages: "Veja todas as coberturas",
          selectBtn: "Selecionar",
          ribbons: {
            popular: "Mais popular",
            oportunity: "Oportunidade",
            recommended: "Plano recomendado",
          },
          multitripLimit: "Viagens ilimitadas durante um ano inteiro, até {{days}} dias cada",
        },
        quoterDetails: {
          person: "Pessoa",
          edit: "Editar",
          requote: "Volte a cotar",
          weekdays: {
            1: "Seg",
            2: "Ter",
            3: "Qua",
            4: "Qui",
            5: "Sex",
            6: "Sáb",
            7: "Dom",
          },
          months: {
            1: "Jan",
            2: "Fev",
            3: "Mar",
            4: "Abr",
            5: "Mai",
            6: "Jun",
            7: "Jul",
            8: "Ago",
            9: "Set",
            10: "Out",
            11: "Nov",
            12: "Dez",
          },
          destinations: {
            afr: "África",
            ams: "América do Sul",
            amn: "América do Norte",
            amc: "América Central",
            asi: "Ásia",
            eur: "Europa",
            oce: "Oceanía",
            syc: "América do Sul e Caribe",
            vds: "Vários Destinos",
            mri: "Marítimo Internacional",
            orm: "Oriente Médio",
          },
        },
        modal: {
          title:
            "Gostaríamos de saber o que você acha para melhorar nossa experiência",
          subtitle:
            "Qual o principal fator que está influenciando sua decisão de não adquirir um seguro viagem hoje?",
          send: "Enviar",
          radio: {
            option1: "Não terminei de ler a cobertura, preciso de mais tempo na página.",
            option2: "Não está claro para mim qual é o melhor plano para minha viagem.",
            option3: "Não entendo completamente o que cada plano cobre e suas diferenças.",
            option4: "Apenas estou pesquisando, não realizarei a compra hoje.",
            option5: "Nenhuma das anteriores",
          },
          contact: "Consultor de contato",
          other: {
            title: "Una última pregunta...",
            subtitle:
              "Há alguma informação adicional que você gostaria de ter para ajudá-lo a tomar uma decisão de compra?",
          },
          success: {
            title: "Muito obrigado! sua opinião é muito importante para nós",
            subtitle:
              "Lembre-se que se você tiver alguma dúvida ou problema pode entrar em contato com nossos consultores.",
            exit: "Sair",
          },
        },
      },
    },
    es: {
      translations: {
        index: {
          back: "Volver",
          title: "Elige el plan que se ajuste a tus necesidades",
          travelDetail: "Detalle de tu viaje (PT)",
          currencyType: "Tipo de moneda",
          moreOptions: "Ver más opciones",
          otherOptions: "Ver otras opciones",
          bannerText: "Cancelación hasta 24 horas antes en todos los planes",
          compareTable: "Compare planes de punta a punta",
        },
        productCard: {
          installments: " cuotas sin interés de",
          totalPrice: "Precio final",
          viewCoverages: "Ver detalle del plan",
          selectBtn: "Seleccionar",
          ribbons: {
            popular: "El más elegido",
            oportunity: "Plan anual",
            recommended: "Plan recomendado",
          },
          multitripLimit: "Viajes ilimitados durante todo un año de hasta {{days}} días cada uno ",
        },
        quoterDetails: {
          person: "Persona",
          edit: "Editar",
          requote: "Volver a cotizar",
          weekdays: {
            0: "Dom",
            1: "Lun",
            2: "Mar",
            3: "Mié",
            4: "Jue",
            5: "Vie",
            6: "Sáb",
          },
          months: {
            1: "Ene",
            2: "Feb",
            3: "Mar",
            4: "Abr",
            5: "May",
            6: "Jun",
            7: "Jul",
            8: "Agos",
            9: "Sep",
            10: "Oct",
            11: "Nov",
            12: "Dic",
          },
          destinations: {
            afr: "África",
            eur: "Europa",
            syc: "Sudamérica y Caribe",
            amn: "Norteamérica",
            asi: "Asia",
            oce: "Oceanía",
            afr: "África",
            vds: "Varios Destinos",
          },
        },
        modal: {
          title:
            "Nos gustaría conocer qué piensas para mejorar nuestra experiencia",
          subtitle:
            "¿Qué factor principal está influyendo en tu decisión de no comprar un seguro de viaje hoy?",
          send: "Enviar",
          radio: {
            option1: "No terminé de leer las coberturas, necesito más tiempo en la página.",
            option2: "No me queda claro cual es el mejor plan para mi viaje.",
            option3: "No comprendo bien qué cubre cada plan y sus diferencias.",
            option4: "Solo estoy averiguando, no estoy listo/a para comprar hoy.",
            option5: "Ninguna de las anteriores",
          },
          contact: "Contactar asesor",
          other: {
            title: "Una última pregunta...",
            subtitle:
              "¿Hay alguna información adicional que te gustaría tener para ayudarte a tomar una decisión de compra?",
          },
          success: {
            title:
              "¡Muchas gracias! Tu opinión es muy importante para nosotros.",
            subtitle:
              "Recuerda que ante cualquier duda o inconveniente puedes contactar a nuestros asesores.",
            exit: "Salir",
          },
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          back: "Volver",
          title: "Elige el plan que se ajuste a tus necesidades",
          travelDetail: "Detalle de tu viaje",
          currencyType: "Tipo de moneda",
          otherOptions: "Ver otras opciones",
          moreOptions: "Ver más opciones",
          bannerText: "Cancelación hasta 24 horas antes en todos los planes",
          compareTable: "Compare planes de punta a punta",
        },
        productCard: {
          installments: " cuotas sin interés de",
          totalPrice: "Precio final",
          viewCoverages: "Ver detalle del plan",
          selectBtn: "Seleccionar",
          ribbons: {
            popular: "El más elegido",
            oportunity: "Plan anual",
            recommended: "Plan recomendado",
          },
          multitripLimit: "Viajes ilimitados durante todo un año de hasta {{days}} días cada uno ",
        },
        quoterDetails: {
          person: "Persona",
          edit: "Editar",
          requote: "Volver a cotizar",
          weekdays: {
            0: "Dom",
            1: "Lun",
            2: "Mar",
            3: "Mié",
            4: "Jue",
            5: "Vie",
            6: "Sáb",
          },
          months: {
            1: "Ene",
            2: "Feb",
            3: "Mar",
            4: "Abr",
            5: "May",
            6: "Jun",
            7: "Jul",
            8: "Agos",
            9: "Sep",
            10: "Oct",
            11: "Nov",
            12: "Dic",
          },
          destinations: {
            afr: "África",
            eur: "Europa",
            syc: "Sudamérica y Caribe",
            amn: "Norteamérica",
            asi: "Asia",
            oce: "Oceanía",
            afr: "África",
            vds: "Varios Destinos",
          },
        },
        modal: {
          title:
            "Nos gustaría conocer qué piensas para mejorar nuestra experiencia",
          subtitle:
            "¿Qué factor principal está influyendo en tu decisión de no comprar un seguro de viaje hoy?",
          send: "Enviar",
          radio: {
            option1: "No terminé de leer las coberturas, necesito más tiempo en la página.",
            option2: "No me queda claro cual es el mejor plan para mi viaje.",
            option3: "No comprendo bien qué cubre cada plan y sus diferencias.",
            option4: "Solo estoy averiguando, no estoy listo/a para comprar hoy.",
            option5: "Ninguna de las anteriores",
          },
          contact: "Contactar asesor",
          other: {
            title: "Una última pregunta...",
            subtitle:
              "¿Hay alguna información adicional que te gustaría tener para ayudarte a tomar una decisión de compra?",
          },
          success: {
            title:
              "¡Muchas gracias! Tu opinión es muy importante para nosotros.",
            subtitle:
              "Recuerda que ante cualquier duda o inconveniente puedes contactar a nuestros asesores.",
            exit: "Salir",
          },
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
