import { useContext, useEffect, useState } from "react";
import {
  checkoutRegisterController,
  getPaymentMethodsGETController,
  productsGETController,
} from "../../dataController/dataController";
import { useTranslation } from "react-i18next";
import { useLoader } from "../../context/loaderContext";
import { StoreContext } from "./../../store/StoreProvider";
import { types } from "./../../store/StoreReducer";
import { useBreakpoint } from "../../context/breakpointContext";
import CurrencySelector from "../CurrencySelector";
import ProductList from "../ProductList";
import { mapProducts } from "../../helpers/dataMapper";
import QuotationDetails from "../QuoterDetails";
import { QuoterComponent } from "quoter365";
import { quoter_config } from "../../config/products.config";
import MoreProductsBtn from "../moreProductsBtn";
import ComparativeProductsTable from "../ComparativeProductsTable";
import CloseIcon from "@mui/icons-material/Close";
import BannerComponent from "../BannerComponent";
import Navbar from "../navbar";
import { useLocation } from "../../context/locationContext";

const Products = () => {
  const { t, i18n } = useTranslation();
  const { setLoader } = useLoader();
  const { currentBreakpoint } = useBreakpoint();
  const [store, dispatch] = useContext(StoreContext);
  const [data, setData] = useState({ list: [], more: [] });
  const [installments, setInstallments] = useState(1);
  let dataLayer = window.dataLayer || [];
  const [dataQuote, setDataQuote] = useState(null);
  const [btnMoreVisibility, setBtnMoreVisibility] = useState(false);
  const [initCoverages, setInitCoverages] = useState(false);
  const [quoterVisibility, setQuoterVisibility] = useState(false);
  const [multitripLayout, setMultitripLayout] = useState(false);

  const _setQuoterVisibility = () => {
    setQuoterVisibility(!quoterVisibility);
  };

  const getData = (data) => {
    setDataQuote(data);
    if (data.fetch_products) {
      getProducts("bsite", installments);
      // getProducts('bsite-see-more');
    }
    return data;
  };

  const { geolocation } = useLocation();

  const productSelected = (product) => {
    if (product?.total_price_promo) {
      product.total_price_back = product.total_price_promo;
    }

    try {
      const formData = new FormData();
      const pax = window.localStorage.getItem("input_age") ?? "undefined";

      const fields = {
        name: "paxname",
        email: "paxemail",
        phone: "paxphone",
        date_from: "date_from",
        date_to: "date_to",
        input_age: "input_age",
        seller_id: "seller_id",
        entity_id: "entity_id",
        from: "country_id",
        country_code: "country_code",
      };

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, localStorage.getItem(value));
      }

      const params = new URLSearchParams(localStorage.getItem("pQuery"));

      formData.append("input_total", "" + pax.split(",").length);
      formData.append("product_id", product.product_id);
      formData.append("total_price", product.total_price_usd);
      formData.append("to", params.get("region_id"));
      formData.append("total_days", params.get("days"));

      var parsedData = {};
      formData.forEach((value, key) => (parsedData[key] = value));

      const fetchData = async () => {
        const checkout_data = await checkoutRegisterController.fetchData({
          body: parsedData,
        });

        const { id } = checkout_data.data;

        dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode: "USD",
            add: {
              products: [
                {
                  name: product.name,
                  id: product.product_id,
                  price: product.total_price_usd,
                  brand: "Assist-365",
                  category: "B2C",
                  quantity: 1,
                },
              ],
            },
          },
        });
        window.location.href = `checkout/${id}`;
      };

      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCurrency = (currency) => {
    if (currency) {
      if (currency.country_code === "BR" || currency.country_code === "PT") {
        i18n.changeLanguage("pt-BR");
        localStorage.setItem("lang", "pt");
      }

      const country_id = currency?.country_id;
      try {
        const fetchData = async () => {
          const result = await getPaymentMethodsGETController.fetchData(
            country_id
          );

          let total_installments = 1;

          if (country_id === 164) {
            total_installments = result.data.installments.Decidir;
            total_installments = total_installments
              .slice(1, -1)
              .split(",")
              [total_installments.slice(1, -1).split(",").length - 1].split(
                ":"
              )[1];
          }

          // CHILE

          if (country_id === 165) {

            total_installments = result.data.installments.Dlocal
              ? result.data.installments.Dlocal
              : "{12:12}";
            total_installments = total_installments
              .slice(1, -1)
              .split(",")
              [total_installments.slice(1, -1).split(",").length - 1].split(
                ":"
              )[1];
          }


          setInstallments(total_installments);
          getProducts("bsite", total_installments);
        };

        fetchData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getProducts = (qsource = "bsite", installments = 1) => {
    if (quoterVisibility) {
      setQuoterVisibility(false);
    }

    try {
      let urlProductQuery = window.localStorage.getItem("pQuery");

      if (urlProductQuery) {
        urlProductQuery = urlProductQuery.slice(1);
        urlProductQuery += "&qsource=" + qsource;

        setLoader(true);
        setBtnMoreVisibility(false);
        setMultitripLayout(false);

        const impressions = [];

        if (qsource === "bsite") {
          setData(() => ({ list: [], more: [] }));
        }

        const fetchData = async () => {
          try {
            const result = await productsGETController.fetchData(
              urlProductQuery
            );
            const resp = result.data;

            if (result.status) {
              //!!!  TEST SI NO HAY PRODUCTOS
              const parsedResp = resp.map((item, index) => {
                item.index = index;
                item.product.installments = parseInt(installments);
                const mappedProduct = mapProducts(item);

                if (mappedProduct.multitrip) {
                  setMultitripLayout(true);
                }

                impressions.push({
                  name: item?.product?.name,
                  id: item?.product?.id,
                  price: item?.product?.total_price_usd,
                  brand: "Assist-365",
                  category: "B2C",
                  list: "Search Results",
                  position: index + 1,
                });

                return mappedProduct;
              });

              const type =
                qsource === "bsite" ? types.setProducts : types.setMore;

              if (qsource === "bsite") {
                setData((data) => ({
                  ...data,
                  list: parsedResp,
                  more: data.more,
                }));

                dataLayer.push({
                  ecommerce: {
                    currencyCode: "USD",
                    impressions: impressions,
                  },
                });
              } else {
                setBtnMoreVisibility(parsedResp.length > 0);

                setData((data) => ({
                  ...data,
                  list: data.list,
                  more: parsedResp,
                }));
                setInitCoverages(true);
              }

              dispatch({
                type: type,
                payload: parsedResp,
              });
            }
            // setError(!resp?.status);
          } catch (error) {
            // setError(false);
          } finally {
            setLoader(false);
            if (qsource === "bsite") {
              getProducts("bsite-see-more", installments);
            }
          }
        };

        fetchData();
      } else {
        throw new Error("cannot find pQuery values");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const seeMoreProducts = () => {
    try {
      const merged_products = store.products.list.concat(store.products.more);

      setData((data) => ({ ...data, list: merged_products }));
      dispatch({
        type: types.setProducts,
        payload: merged_products,
      });
      setBtnMoreVisibility(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getConfig = (mode) => {
    if (mode === "default") {
      const config = { ...quoter_config, view_mode: "default" };
      return config;
    } else {
      return quoter_config;
    }
  };

  const [modalCoverages, setModalCoverages] = useState({
    show: false,
    idx: null,
    data: {},
  });

  const handleModalCoverages = () => {
    setModalCoverages({ ...modalCoverages, show: !modalCoverages.show });
  };

  const getMaxWidthClasses = () => {
    const max_widths = {
      xs: "max-w-[345px] ",
      sm: "max-w-[660px] ",
      md: "max-w-[768px] ",
      lg: "max-w-[1056px] ",
      xl: "max-w-[1360px] ",
      "2xl": "max-w-[1410px] ",
    };
    return max_widths[currentBreakpoint] || "";
  };

  return (
    <>
      <Navbar />
      <div
        className={
          "bg-ribbon-bg md:hidden fixed flex items-center justify-start p-4 flex-col inset-0 pt-20 z-10" +
          (quoterVisibility ? " block" : " hidden")
        }
      >
        <div
          className="absolute top-2 right-2 z-50"
          onClick={() => setQuoterVisibility(false)}
        >
          <CloseIcon sx={{ color: "#000" }} />
        </div>
        <QuoterComponent
          className="relative"
          view_mode={"default"}
          config={getConfig("default")}
          quoterData={getData}
        />
      </div>
      <div className="pt-10 flex flex-col items-center justify-center w-full bg-[#EFF1F4] relative">
        <div className={"section-wrapper w-full " + getMaxWidthClasses()}>
          <h1 className="text-center text-[#006FE8] font-titillium text-3xl lg:text-4xl font-bold w-full">
            {t("index.title")}
          </h1>
          <div
            className="flex-col 
          xl:flex-row 
          flex 
          items-center 
          justify-between 
          w-full mb-8 md:mb-0 sm:max-w-[560px] md:max-w-[640px] lg:max-w-full xl:max-w-[1200px] 2xl:max-w-[1396px] mx-auto lg:px-4"
          >
            {dataQuote && (
              <QuotationDetails
                getConfig={getConfig}
                getData={getData}
                data={dataQuote}
                _setQuoterVisibility={_setQuoterVisibility}
                quoterVisibility={quoterVisibility}
              />
            )}

            <CurrencySelector handleCurrency={handleCurrency} />
          </div>
          <ProductList
            setModalCoverages={setModalCoverages}
            productSelected={productSelected}
            multitripLayout={multitripLayout}
          />
          {btnMoreVisibility && (
            <MoreProductsBtn seeMoreProducts={seeMoreProducts} />
          )}

          <BannerComponent />

          {data?.list?.length > 0 && (
            <ComparativeProductsTable
              modalCoverages={modalCoverages}
              setModalCoverages={setModalCoverages}
              initCoverages={initCoverages}
              data={data}
              productSelected={productSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
