import React, { useEffect } from "react";
import IcoPin from "../../assets/icon/icoPin";
import IcoCalendar from "../../assets/icon/icoCalendar";
import IcoUser from "../../assets/icon/icoUser";
import { useTranslation } from "react-i18next";
import { QuoterComponent } from "quoter365";
import { useBreakpoint } from "../../context/breakpointContext";

const QuotationDetails = ({
  data,
  getConfig,
  getData,
  _setQuoterVisibility,
  quoterVisibility,
}) => {
  const { t } = useTranslation();
  const { currentBreakpoint } = useBreakpoint();
  const dates_labels = {
    date_from: "",
    date_to: "",
  };

  const date_from = new Date(data?.dates?.date_from);
  const date_to = new Date(data?.dates?.date_to);

  const date_from_label = `${t(
    "quoterDetails.weekdays." + parseInt(date_from.getDay() + 1)
  )}, ${date_from.getDate() + 1} de ${t(
    "quoterDetails.months." + (date_from.getMonth() + 1)
  )} de ${date_from.getFullYear()} `;

  const date_to_label = `${t(
    "quoterDetails.weekdays." + parseInt(date_to.getDay() + 1)
  )}, ${date_to.getDate() + 1} de ${t(
    "quoterDetails.months." + (date_to.getMonth() + 1)
  )} de ${date_to.getFullYear()} `;

  dates_labels.date_from = date_from_label;
  dates_labels.date_to = date_to_label;
  return (
    <>
      <div className="text-xs flex flex-col items-start sm:items-center lg:items-start  justify-center w-full mt-8 md:mt-6 mb-8 text-text-secondary">
        <div className="flex flex-col">
          <div>
            <span className="w-full font-titillium block text-left lg:text-left font-semibold text-base pb-2">
              {t("index.travelDetail")}
            </span>
          </div>
          <div
            className="w-full flex flex-col lg:flex-row lg:items-center"
            onClick={_setQuoterVisibility}
          >
            <div className="border border-[#BEC2C5] bg-[#f7f7f7] rounded-lg py-3 px-4 md:p-4 md:min-w-[600px] w-full lg:min-w-[620px]">
              <div className="grid grid-cols-1 md:grid-cols-[auto_auto_auto] sm:gap-0 gap-y-2">
                <div className="flex space-x-2">
                  <IcoPin />
                  <span>
                    {t(`quoterDetails.destinations.${data?.destination?.code}`)}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <IcoCalendar />
                  <span>
                    {dates_labels.date_from} - {dates_labels.date_to}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <IcoUser />
                  <span>
                    {data?.insured} pasajero{data?.insured > 1 && <>s</>}
                  </span>
                </div>
              </div>
            </div>
            <button
              disabled={quoterVisibility}
              className="mt-2 disabled:opacity-45 lg:mt-0 lg:ml-2 w-full rounded-md p-3 bg-popular-bg text-text-primary font-semibold text-base shadow-sm"
            >
              {t("quoterDetails.requote")}
            </button>
          </div>
        </div>
        {currentBreakpoint !== "xs" && currentBreakpoint !== "sm" && (
          <div
            className={
              "quoter-wrapper bg-ribbon-bg md:rounded-[20px]  mt-2 p-4 w-full  md:max-w-[600px] lg:max-w-[774px] absolute md:top-[176px] lg:top-[180px] z-[999]" +
              (quoterVisibility ? " block" : " hidden")
            }
          >
            <QuoterComponent
              className="relative"
              view_mode={"default"}
              config={getConfig("default")}
              quoterData={getData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default QuotationDetails;
