import React from "react";
import { capitalizeFirstLetter } from "./../utils/utils";
import Button from "@mui/material/Button";
import { getProductType } from "../config/products.config";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";

const CardComponent = (props) => {
  const { t } = useTranslation();
  const {
    name,
    basic_price,
    total_price,
    discount,
    installments,
    installment_amount,
    type,
    currency_symbol,
    multitrip,
    days
  } = props.product.product;
  const benefits = props.product.benefit;

  const currencySymbol = capitalizeFirstLetter(
    currency_symbol
  );

  const normal =
    !type || (type !== "popular" && type !== "oportunity")
      ? " bg-ribbon-bg text-ribbon-color"
      : "";
  const popular_recommended =
    type === "popular" || type === "recommended" ? " bg-ribbon-bg-popular text-ribbon-color-popular" : "";
  const oportunity =
    type === "oportunity" || multitrip
      ? " bg-ribbon-bg-oportunity text-ribbon-color-oportunity"
      : "";

  const { gotoCoverages, selectProduct } = props;

  return (
    <>
      <Fade in={true}>
        <div
          className={
            "product-card-container relative md:mx-4" +
            (type ? " mt-[52px] " : " mt-4 md:mt-[52px]") +
            (!type ? " mt-4 md:mt-[52px]" : " mt-4")
          }
        >
          {type && (type === "oportunity" || type === "popular" || type === "recommended") && (
            <div
              className={
                "product-card-type text-center text-base font-bold uppercase " +
                (type === "oportunity"
                  ? "bg-oportunity-bg-type text-white"
                  : "") +
                (type === "popular" || type === "recommended" ? "bg-popular-bg-type text-white" : "")
              }
            >
              {type && type === "oportunity" ? t("productCard.ribbons.oportunity")
                : type === "recommended" ? t("productCard.ribbons.recommended") : t("productCard.ribbons.popular")}
            </div>
          )}

          <div
            className={
              "product-card-header " +
              (!type ? "text-blue-primary" : "") +
              (type && type !== "oportunity" && type !== "popular" && type !== "recommended" && !multitrip
                ? "text-blue-primary bg-white"
                : "") +
              (type === "popular" || type === "recommended" ? "text-neutral-black bg-popular-bg" : "") +
              (type === "oportunity" ? "text-white bg-oportunity-bg" : "") +
              (multitrip ? "text-white bg-oportunity-bg" : "")
            }
          >
            {discount && (
              <div className={`ribbon ${normal} ${popular_recommended} ${oportunity}`}>
                {discount} OFF
              </div>
            )}
            <span className="product-name font-bold text-4xl pb-4 capitalize">
              {name.toLowerCase()}
            </span>
            {installments && (
              <span className="installments text-xl mb-2 text-center">
                {installments > 1 && installments}
                {installments > 1 && t("productCard.installments")}
                <span className="font-bold block text-5xl capitalize">
                  {currencySymbol == 'Ars' ? '$' : currencySymbol} {installment_amount}{" "}
                  {currencySymbol == 'Ars' ? " Ars " : ""}
                </span>
              </span>
            )}
            <div className="product-price w-full flex items-center justify-center">
              <div className="flex flex-col items-end">
                <span className="product-price-base text-sm">
                  {t("productCard.totalPrice")} {currencySymbol} <span className="line-through">{basic_price}</span>
                </span>
                <span className="product-price-final text-sm font-bold">
                  {currencySymbol} {total_price}
                </span>
              </div>
            </div>
            {multitrip && (<div className="absolute left-0 right-0 bottom-0 multitrip-message border-b-[1px] font-bold text-center py-1 px-4 font-sans">
              {t("productCard.multitripLimit", { days: days })}</div>)}
          </div>
          <div className="product-card-body">
            <div className="product-benefits">
              <ul className="space-y-2 color-[#414D58] text-sm">
                {benefits.length > 0 &&
                  benefits.map((benefit, index) => {
                    return (
                      <li key={index} className="flex flex-row gap-2">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.5421 6.11809C17.9326 6.50861 17.9326 7.14178 17.5421 7.5323L9.54207 15.5323C9.15154 15.9228 8.51838 15.9228 8.12785 15.5323L4.12785 11.5323C3.73733 11.1418 3.73733 10.5086 4.12785 10.1181C4.51838 9.72756 5.15154 9.72756 5.54207 10.1181L8.83496 13.411L16.1279 6.11809C16.5184 5.72756 17.1515 5.72756 17.5421 6.11809Z"
                            fill="#79BF30"
                          />
                        </svg>
                        {benefit.price} {benefit.name}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <button
              onClick={() => gotoCoverages(1)}
              className="my-6 text-blue-primary text-base underline"
            >
              {t("productCard.viewCoverages")}
            </button>
          </div>

          <div className="product-card-footer">
            <Button
              onClick={() => selectProduct(props.product.product)}
              fullWidth
              variant="contained"
              size="large"
              style={{
                height: "60px",
                textTransform: "capitalize",
                background: "#2F6DE0",
                fontFamily: "Titillium Web",
                fontWeight: 700,
                color: "#fff",
                fontSize: "20px",
                padding: "18px 0",
              }}
            >
              {t("productCard.selectBtn")}
            </Button>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default CardComponent;
