// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quoter-details img {
  margin-right: 8px;
}
.quoter-details div {
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 5px;
  font-family: "Inter";
  font-size: 16px;
  text-transform: capitalize;
}
.quoter-details div {
  @media screen AND (min-width: 768px) {
    padding: 0 0 0 12px;
    align-items: center;
  }
}
.quoter-details div:nth-child(4) span {
  text-transform: lowercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/quoter.details.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE;IACE,mBAAmB;IACnB,mBAAmB;EACrB;AACF;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".quoter-details img {\n  margin-right: 8px;\n}\n.quoter-details div {\n  display: flex;\n  align-items: flex-start;\n  justify-items: flex-start;\n  margin-bottom: 5px;\n  font-family: \"Inter\";\n  font-size: 16px;\n  text-transform: capitalize;\n}\n.quoter-details div {\n  @media screen AND (min-width: 768px) {\n    padding: 0 0 0 12px;\n    align-items: center;\n  }\n}\n.quoter-details div:nth-child(4) span {\n  text-transform: lowercase;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
