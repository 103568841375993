import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import no from "./../assets/img/no.svg";
import included from "./../assets/img/included.svg";
import CardComponent from "./CardComponent";
import { QuoterComponent } from "quoter365";
import QuoterDetails from "./quoter.details.component";
import logo from "./../assets/img/logo.png";
import ComparativeTable from "./ComparativeTable";
import QuoterWrapper from "./quoterWrapper";
import BackComponent from "./backComponent";
import MoreProductsBtn from "./moreProductsBtn";
import BannerComponent from "./BannerComponent";
import CurrencyComponent from "./currencyComponent";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PhoneSupport from "./PhoneSupport/phoneSupport";
import SideBar from "./PhoneSupport/sideBar";
// import DropOutSurvey from "./dropOutSurvey"
//? general setup
import {
  products_order,
  quoter_config,
  countries_promo,
  products_without_discount,
  country_promo_installments,
  getProductType,
} from "./../config/products.config";

//? utils
import { _getInstallments } from "../utils/utils";
import { getPaymentMethodsGETController } from "./../dataController/dataController";

const Products = () => {
  let dataLayer = window.dataLayer || [];
  // let ttq = window.ttq || [];

  const getConfig = (mode) => {
    if (mode === "default") {
      const config = { ...quoter_config, view_mode: "default" };
      return config;
    } else {
      return quoter_config;
    }
  };

  const { t, i18n } = useTranslation();
  const { language } = useLanguage();
  const [detailsVisibility, setDetailsVisibility] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [currencies, setCurrencies] = useState({ list: [] });
  const [dataQuote, setDataQuote] = React.useState({});
  const [spinner, setSpinner] = React.useState(false);
  const [countryPromo, setCountryPromo] = React.useState({});
  const [moreProducts, setMoreProducts] = React.useState({
    btn_more_visibility: false,
    products: [],
  });
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState("");
  const [currentCurrency, setCurrentCurrency] = React.useState("");
  const [moreProductsBtnVisibility, setMoreProductsBtnVisibility] =
    useState(false);
  const [geo, setGeo] = React.useState();
  const [whatsapp, setWhatsapp] = useState(null);
  const [installments, setInstallments] = useState(1);
  // const [modalOpen, setModalOpen] = useState(false);

  //? breakpoints

  const isXS = useMediaQuery({ query: "(max-width: 639px)" });
  const isSM = useMediaQuery({
    query: "(min-width: 640px) AND (max-width: 767px",
  });
  const isMD = useMediaQuery({
    query: "(min-width: 768px) AND (max-width: 1023px",
  });
  const isLG = useMediaQuery({
    query: "(min-width: 1024px) AND (max-width: 1280px",
  });
  const isXL = useMediaQuery({
    query: "(min-width: 1280px) AND (max-width: 1535px",
  });
  const is2XL = useMediaQuery({ query: "(min-width: 1536px)" });

  //? Comparative Table

  const comparativeTableData = {
    ids: [],
    names: [],
    coverages_data: [],
    types: [],
  };

  const [comparativeTable, setComparativeTable] =
    React.useState(comparativeTableData);

  useEffect(() => {
    if (currentCurrency) {
      try {
        const fetchData = async () => {
          const result = await getPaymentMethodsGETController.fetchData(
            currentCurrency
          );

          let total_installments = 1;

          if (currentCurrency === "164") {
            total_installments = result.data.installments.Decidir;

            total_installments = total_installments
              .slice(1, -1)
              .split(",")
              [total_installments.slice(1, -1).split(",").length - 1].split(
                ":"
              )[1];
          }
          setInstallments(total_installments);
          getProducts("bsite", total_installments);
        };

        fetchData();
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentCurrency]);

  useEffect(() => {
    processCoverages();
  }, [products]);

  useEffect(() => {
    if (comparativeTable.coverages_data.length > 0) {
      coverNav("init", true);
    }
  }, [comparativeTable]);

  const [dataTable, setDataTable] = React.useState({ ids: [], limit: 1 });

  const _breakpoints = new Map([
    ["xs", 1],
    ["sm", 2],
    ["md", 3],
    ["lg", 3],
    ["xl", 4],
    ["2xl", 4],
  ]);

  const [breakpoint_limit, setBreakpointLimit] = React.useState(_breakpoints);

  const coverNav = (mode, init = false) => {
    if (mode === "init") {
      const ids = Array.from(
        { length: products.length },
        (_, index) => index + 1
      );

      const limit = getCurrentBreakpointLimit();
      setDataTable({ ids: ids, limit: limit });
    }

    if (mode === "next") {
      const value = [...dataTable.ids];
      value.push(value.shift());
      setDataTable({ ...dataTable, ids: value });
    }

    if (mode === "prev") {
      const value = [...dataTable.ids];
      value.unshift(value.pop());
      setDataTable({ ...dataTable, ids: value });
    }
  };

  const processCoverages = () => {
    const pre_table_data = {
      ids: [],
      names: [],
      type: [],
    };

    if (products.length > 0) {
      products.forEach((element) => {
        pre_table_data.ids.push(element.product.id);
        pre_table_data.names.push(element.product.name.trim().toLowerCase());
        pre_table_data.type.push(element.product.type);
      });

      if (pre_table_data.ids.length > 0) {
        fetch(
          "https://app.assist-365.com/api/coverages?product_id=" +
            pre_table_data.ids.join(",")
        )
          .then((res) => res.json())
          .then((coverages) => {
            const coverages_data = [];
            const collection = [];

            for (let id of Object.keys(coverages)) {
              coverages[id].forEach((e, i) => {
                collection.push({ id: e.id, name: e.name });
              });
            }

            collection.filter((item) => {
              var i = coverages_data.findIndex(
                (x) => x.id === item.id && x.name === item.name
              );
              if (i <= -1) {
                coverages_data.push(item);
              }
              return null;
            });

            let counter = 1;

            for (let id of pre_table_data.ids) {
              coverages_data.map((i) => {
                const ref = coverages[id].find((elem) => elem.id === i.id);

                if (ref) {
                  let isImg = ref.price;
                  const match = isImg.trim().toLowerCase();

                  if (
                    match.includes("no inclu") ||
                    match.includes("não inclu")
                  ) {
                    isImg = <img className="mx-auto" src={no} />;
                  }
                  if (
                    match.includes("incl") &&
                    !match.includes("no inclu") &&
                    !match.includes("não incl")
                  ) {
                    isImg = <img className="mx-auto" src={included} />;
                  }

                  i["price" + counter] = isImg;

                  // FEATURE COLORED

                  const type = pre_table_data.type[counter - 1];
                  let classStyle = "";
                  if (type === "popular" || type === "recommended") {
                    classStyle = "cellClass-green";
                  }
                  if (type === "oportunity" || type === "multitrip") {
                    classStyle = "cellClass-violet";
                  }

                  i["cellClass" + counter] = classStyle;
                }
                // i["price" + counter] = ref ? ref.price : isImg;
              });
              counter++;
            }

            setComparativeTable({
              ids: pre_table_data.ids,
              names: pre_table_data.names,
              coverages_data: coverages_data,
              types: pre_table_data.type,
            });
          });
      }
    } else {
      setComparativeTable({
        ids: [],
        names: [],
        coverages_data: [],
        types: [],
      });
    }
  };

  const getCurrentBreakpointLimit = () => {
    let limit = breakpoint_limit.get(getCurrentBreakpoint());
    if (products.length < limit) {
      limit = products.length;
    }
    setDataTable({ ...dataTable, limit: limit });
    return limit;
  };

  useEffect(() => {
    setCurrentBreakpoint(getCurrentBreakpoint());
    getCurrentBreakpointLimit();
  }, [isXS, isSM, isMD, isLG, isXL, is2XL]);

  const getCurrentBreakpoint = () => {
    let breakpoint = "";
    if (isXS) {
      breakpoint = "xs";
    }
    if (isSM) {
      breakpoint = "sm";
    }
    if (isMD) {
      breakpoint = "md";
    }
    if (isLG) {
      breakpoint = "lg";
    }
    if (isXL) {
      breakpoint = "xl";
    }
    if (is2XL) {
      breakpoint = "2xl";
    }
    return breakpoint;
  };

  const setMarket = async (market) => {
    const urlParams = window.location.search;
    const urlQuery = new URLSearchParams(urlParams);
    const geo = await geoLocation(true, market.toUpperCase()).then((res) => {
      return res;
    });

    localStorage.setItem("country_code", geo.country);
    localStorage.setItem("country_name", geo.country_name);
    localStorage.setItem("country_id", geo.country_id);
    localStorage.setItem("currency_symbol", geo.currency_symbol);

    Currency("set", true, geo.country_id);

    const date_from = urlQuery.get("date_from");
    const date_to = urlQuery.get("date_to");
    let pQuery = `?region_id=${urlQuery.get(
      "region_id"
    )}&country_code=${urlQuery.get("country_code")}&country_id=${urlQuery.get(
      "country_id"
    )}&days=${urlQuery.get("days")}&entity_id=${urlQuery.get(
      "entity_id"
    )}&to_year=${urlQuery.get("to_year")}&insured=${urlQuery.get(
      "insured"
    )}&date_from=${date_from}&date_to=${date_to}`;

    if (urlQuery.has("ages_1")) {
      pQuery += `&ages_1=${urlQuery.get("ages_1")}`;
    }

    if (urlQuery.has("ages_2")) {
      pQuery += `&ages_2=${urlQuery.get("ages_2")}`;
    }

    // )}&ages_1=${urlQuery.get("ages_1")}&ages_2=${urlQuery.get(
    //   "ages_2"

    let ageQuery = "";

    const input_ages = urlQuery.get("input_age").split(",");
    const age_group = {
      age_1: 0,
      age_2: 0,
    };

    input_ages.forEach((paxAge) => {
      ageQuery += `&age[]=${paxAge}`;
      if (parseInt(paxAge) <= 74) {
        age_group.age_1++;
      } else {
        age_group.age_2++;
      }
    });

    pQuery += ageQuery;

    localStorage.setItem("pQuery", pQuery);
    // localStorage.setItem('to', urlQuery.get('to'));
    // localStorage.setItem('month', urlQuery.get('month'));
    localStorage.setItem("days", urlQuery.get("days"));
    localStorage.setItem("date_from", new Date(urlQuery.get("date_from")));
    localStorage.setItem("date_to", new Date(urlQuery.get("date_to")));
    localStorage.setItem("input_age", urlQuery.get("input_age"));

    localStorage.setItem(
      "ages_1",
      urlQuery.has("ages_1") ? urlQuery.get("ages_1") : 0
    );
    localStorage.setItem(
      "ages_2",
      urlQuery.has("ages_2") ? urlQuery.get("ages_2") : 0
    );

    localStorage.setItem("quote_type", "default");

    console.log("Data from urlParams to LocalStorage <done>");

    // checkMoreProducts();
    getProducts();
  };

  const geoLocation = async (flag = false, country_code = null) => {
    let query = "";
    if (flag && country_code) {
      query = `?flag=${flag}&country_code=${country_code}`;
    }

    return await fetch(`https://app.assist-365.com/api/geolocation${query}`)
      .then((response) => response.json())
      .then((response) => {
        setGeo(response);
        return response;
      })
      .catch((err) => console.error(err));
  };

  const getCurrencies = async () => {
    return await fetch("https://app.assist-365.com/api/currency-list")
      .then((response) => response.json())
      .then((res) => {
        const res_order = res.sort(function (a, b) {
          var nameA = a.country_name.toUpperCase();
          var nameB = b.country_name.toUpperCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

        return res_order;
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      const _currencies = await getCurrencies().then((res) => {
        return res;
      });

      let country_id;

      if (i18n.language === "pt-BR") {
        country_id = 155;
      } else {
        let urlProductQuery = window.localStorage.getItem("pQuery");
        if (urlProductQuery) {
          urlProductQuery.split("&").forEach((elem) => {
            if (elem.substring(0, 10) === "country_id") {
              country_id = elem.split("=")[1];
            }
          });
        } else {
          country_id = localStorage.getItem("country_id");
        }
      }

      const currency_selected = parseInt(country_id);
      setCurrentCurrency(currency_selected.toString());
      setCurrencies({ ...currencies, list: _currencies });
      // Currency("set", true, localStorage.getItem("country_id"));
      Currency("set", true, currency_selected);
    };

    fetchCurrencies();

    const urlParams = window.location.search;
    const urlQuery = new URLSearchParams(urlParams);

    if (urlQuery.has("market")) {
      console.log("MARKET DETECTED");
      setMarket(urlQuery.get("market"));
    } else {
      // checkMoreProducts();
      // getProducts();
      // getProducts('bsite-see-more');
    }

    // const timer = setTimeout(() => {
    //   setModalOpen(true);
    // }, 77000); // en milisegundos ej: 1000 = 1s
    // return () => clearTimeout(timer);
  }, []);

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  const chatCenter = (country_id) => {
    return [52, 135, 158, 199, 157, 6, 161, 155, 165].includes(
      parseInt(country_id)
    );
  };

  useEffect(() => {
    if (geo) {
      let today_day = geo?.today_day;
      let today_hour = geo?.today_hour;

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("today_day") || urlParams.has("today_hour")) {
        if (urlParams.get("today_day")) {
          today_day = urlParams.get("today_day").toString();
        }
        if (urlParams.get("today_hour")) {
          today_hour = urlParams.get("today_hour").toString();
        }
      }

      // (today_day === "6") ||
      // (today_day === "7" && today_hour < "22:01"))

      let whatsapp_number = "";
      if (
        today_day === "6" ||
        (today_day === "5" && today_hour < "21:00") ||
        (today_day === "1" && today_hour < "15:00") ||
        (today_day === "7" && today_hour < "22:01")
      ) {
        whatsapp_number = "5491122647575";
      } else if (
        geo?.country_id === 155 ||
        geo?.country_id === 201 ||
        i18n.language === "pt-BR"
      ) {
        whatsapp_number = "5548988523408";
      } else {
        whatsapp_number = chatCenter(geo.country_id)
          ? "5491122647575"
          : geo.header_whatsapp.substring(1);
      }
      setWhatsapp(whatsapp_number);
    }
  }, [geo]);

  const toggleDetails = () => {
    setDetailsVisibility(!detailsVisibility);
    const elem = document.getElementById("pseudo-accordion");
    if (elem) {
      elem.classList.toggle("is-active");
    }
  };

  useEffect(() => {
    // checkMoreProducts();
  }, [dataQuote]);

  const getProducts = (qsource = "bsite", cuotas = 1) => {
    try {
      let urlProductQuery = window.localStorage.getItem("pQuery");
      if (urlProductQuery) {
        urlProductQuery += "&provider=2";

        if (localStorage.getItem("quote_type") !== "nomadcare") {
          urlProductQuery += "&qsource=" + qsource;
        }

        setSpinner(true);

        setMoreProductsBtnVisibility(false);

        if (qsource !== "bsite-see-more") {
          setProducts([]);
          setMoreProducts({ products: [], btn_more_visibility: false });
        }

        fetch("https://app.assist-365.com/api/products" + urlProductQuery)
          .then((res) => res.json())
          .then((res) => {
            if (res.length > 0) {
              const impressions = [];

              if (qsource === "bsite") {
                //? check if country is in promo

                const country_id = window.localStorage.getItem("country_id");
                const check_country_promo = countries_promo.filter(
                  (country) => country.id === parseInt(country_id)
                );
                if (check_country_promo.length > 0) {
                  setCountryPromo(check_country_promo[0]);
                }
              }

              const neworder = [];
              const pre_products = JSON.parse(JSON.stringify(res));

              //? set products order + impressions

              pre_products.map((p, index) => {
                //? check if product have discount

                if (
                  p.product.promo != "Ninguna" &&
                  products_without_discount.indexOf(
                    p.product.name.trim().toLowerCase()
                  ) === -1
                ) {
                  const check_promotion = p.product.promo.trim().slice(-2);
                  let discount = /\d/.test(check_promotion)
                    ? check_promotion
                    : -1;
                  p.product.discount =
                    discount == -1 || discount == 0
                      ? null
                      : parseInt(discount) + "%";
                } else {
                  p.product.discount = null;
                }

                //? check if product is multitrip

                if (p.product.name.toLowerCase().includes("multi")) {
                  p.product["multitrip"] = true;

                  //? set total days for multitrip

                  p.product.name.split(" ").map((word) => {
                    if (/\d/.test(word)) {
                      p.product["days"] = parseInt(word);
                    }
                  });
                } else {
                  p.product["multitrip"] = false;
                }

                if (qsource === "bsite") {
                  // const impression = {
                  //   "item_name": p["product"]["name"].trim(),
                  //   "item_id": p["product"]["id"]
                  // };
                  // const voucher = localStorage.getItem("voucher") ?? null;
                  // if (voucher) {
                  //   impression["coupon"] = voucher;
                  // }
                  // impressions.push(impression);

                  impressions.push({
                    name: p["product"]["name"].trim(),
                    id: p["product"]["id"],
                    price: p["product"]["total_price_usd"],
                    brand: "Assist-365",
                    category: "B2C",
                    list: "Search Results",
                    position: index + 1,
                  });
                }

                //? set installments
                // if (country_promo_installments()) {
                const { installments, installment_amount } = _getInstallments(
                  p.product["total_price"],
                  cuotas
                );
                p.product["installments"] = installments;
                p.product["installment_amount"] = installment_amount;
                // }

                //? installments ARGENTINA

                if (localStorage.getItem("country_id") === "164") {
                  const { installments, installment_amount } = _getInstallments(
                    p.product["total_price"],
                    cuotas
                  );
                  p.product["installments"] = installments;
                  p.product["installment_amount"] = installment_amount;
                }

                //? set product type
                if (qsource === "bsite") {
                  const geo = location.pathname.split("/")[1];
                  const country_id = localStorage.getItem("country_id");

                  if (index === 1) {
                    const product_name = p.product.name.trim().toLowerCase();
                    let product_type = "";

                    if (
                      product_name === "world cover" ||
                      (geo === "br" &&
                        (product_name === "essential trip" ||
                          product_name === "europe care"))
                    ) {
                      product_type = "popular";
                    } else {
                      product_type = "recommended";
                    }

                    p.product["type"] = product_type;
                  }

                  // if ((index + 1) === res.length - 1) {
                  //   p.product["type"] = "popular";
                  // }
                }

                if (!p.product.hasOwnProperty("type")) {
                  p.product["type"] = getProductType(p.product["name"]);
                }

                neworder.push(p);
              });

              if (qsource === "bsite") {
                // dataLayer.push({
                //   "event": "view_item_list",
                //   "ecommerce": {
                //     "items": impressions
                //   }
                // });

                dataLayer.push({
                  ecommerce: {
                    currencyCode: "USD",
                    impressions: impressions,
                  },
                });

                setProducts(neworder);
              }

              if (qsource === "bsite-see-more") {
                if (neworder.length > 0) {
                  setMoreProducts({
                    products: neworder,
                    btn_more_visibility: true,
                  });
                }
              }
            } else {
              if (qsource != "bsite-see-more") {
                setProducts([]);
              }
            }
          })
          .finally(() => {
            setSpinner(false);
            if (qsource === "bsite") {
              getProducts("bsite-see-more", cuotas);
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const productSelected = (product) => {
    if (product.hasOwnProperty("total_price_promo")) {
      product.total_price_back = product.total_price_promo;
    }

    try {
      const formData = new FormData();
      const pax = window.localStorage.getItem("input_age") ?? "undefined";

      const fields = {
        name: "paxname",
        email: "paxemail",
        phone: "paxphone",
        date_from: "date_from",
        date_to: "date_to",
        input_age: "input_age",
        seller_id: "seller_id",
        entity_id: "entity_id",
        from: "country_id",
        country_code: "country_code",
        total_days: "days",
      };

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, localStorage.getItem(value));
      }

      const params = new URLSearchParams(localStorage.getItem("pQuery"));

      formData.append("input_total", "" + pax.split(",").length);
      formData.append("product_id", product.id);
      formData.append("total_price", product.total_price_usd);
      formData.append("to", params.get("region_id"));
      formData.append("total_days", params.get("days"));

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      fetch("https://app.assist-365.com/api/checkout-register", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            // const impression = {
            //   "item_name": product["name"].trim(),
            //   "item_id": product["id"]
            // };

            // const voucher = localStorage.getItem("voucher") ?? null;
            // if (voucher) {
            //   impression["coupon"] = voucher;
            // }

            // dataLayer.push({ ecommerce: null });

            // const impression_product = {
            //   "event": "add_to_cart",
            //   "currency": product.currency_symbol,
            //   "value": product.total_price,
            //   "ecommerce": {
            //     "items": [impression]
            //   }
            // }

            // dataLayer.push(impression_product);

            //? T I K T O K  E V E N T  > ADDTOCART
            // ttq.track('addItem', {
            //   "value": "1", // number. Value of the order or items sold. Example: 100.
            //   "currency": localStorage.getItem("currency_symbol") || "USD", // string. The 4217 currency code. Example: "USD".
            //   "contents": [
            //     {
            //       "content_id": product.id, // string. ID of the product. Example: "1077218".
            //       "content_type": "product", // string. Either product or product_group.
            //       "content_name": product.name // string. The name of the page or product. Example: "shirt".
            //     }
            //   ]
            // })

            //? D A T A L A Y E R > ADDTOCART

            dataLayer.push({
              event: "addToCart",
              ecommerce: {
                currencyCode: "USD",
                add: {
                  products: [
                    {
                      name: product.name,
                      id: product.id,
                      price: product.total_price_usd,
                      brand: "Assist-365",
                      category: "B2C",
                      quantity: 1,
                    },
                  ],
                },
              },
            });

            window.location.href = `checkout/${data.id}`;
          } else {
            console.error(data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToCover = (data) => {
    if (data) {
      const elmnt = document.getElementById("comparative-table");
      elmnt?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getData = (data) => {
    setDataQuote(data);

    console.log(data);
    if (data.fetch_products) {
      getProducts("bsite", installments);
      // getProducts('bsite-see-more');
    }
    return data;
  };

  const getQueryParams = (search_params) => {
    let params = "";
    search_params.forEach((value, key) => {
      params += key + "=" + value + "&";
    });

    params = params.slice(0, -1);
    return params;
  };

  const Currency = async (mode, get_products = true, currency_value = null) => {
    if (mode === "set") {
      try {
        const _currencies = await getCurrencies().then((res) => {
          return res;
        });

        const data_currency = _currencies.filter(
          (currency) => currency.country_id === Number(currency_value)
        )[0];

        const geo = await geoLocation(true, data_currency.country_code);
        setGeo(geo);
        setCurrentCurrency(currency_value.toString());

        var url = new URL(
          "https://assist-365.com/" + window.localStorage.getItem("pQuery")
        );
        var search_params = url.searchParams;

        search_params.set("currency_country_id", geo.country_id);
        search_params.set("country_code", geo.country);
        search_params.set("country_id", geo.country_id);
        search_params.set("entity_id", geo.entity);

        const urlProductQuery = getQueryParams(search_params);

        localStorage.setItem("pQuery", "?" + urlProductQuery);
        localStorage.setItem("currency_symbol", geo.currency_symbol);
        localStorage.setItem("locale", geo.locale);
        localStorage.setItem("country_code", geo.country);
        localStorage.setItem("country_name", geo.country_name);
        localStorage.setItem("entity_id", geo.entity);
        localStorage.setItem("seller_id", geo.seller);
        localStorage.setItem("country_id", geo.country_id);

        if (getProducts) {
          // getProducts();
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (mode === "get-cid") {
      let country_id = "";

      if (i18n.language === "pt-BR") {
        country_id = 155;
      } else {
        let urlProductQuery = window.localStorage.getItem("pQuery");
        if (urlProductQuery) {
          urlProductQuery.split("&").forEach((elem) => {
            if (elem.substring(0, 10) === "country_id") {
              country_id = elem.split("=")[1];
            }
          });
        } else {
          country_id = localStorage.getItem("country_id");
        }
      }

      return Number(country_id);
    }
  };

  const seeMoreProducts = () => {
    try {
      setMoreProductsBtnVisibility(true);
      setMoreProducts({ ...moreProducts, btn_more_visibility: false });
      const totalProducts = products.concat(moreProducts.products);
      setProducts(totalProducts);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <DropOutSurvey
        className="mx-auto my-auto rounded-md justify-center text-center"
        isOpen={modalOpen}
        closeModal={closeModal}
        whatsapp={whatsapp}
        country_id={geo?.country_id}
      /> */}

      {/* {(isXL || is2XL) && ( */}
      <div className="w-full bg-blue-quartary items-center justify-center p-4 fixed z-10 hidden xl:flex">
        <div className="max-w-[1960px] flex items-center justify-around">
          {isXL ||
            (is2XL && (
              <img src={logo} className="mr-[32px]" alt="ASSIST 365" />
            ))}
          <QuoterComponent
            className="relative"
            view_mode={"landscape"}
            config={getConfig("landscape")}
            quoterData={getData}
          />
        </div>
        {geo?.country_id === 164 && (isXL || is2XL) && (
          <PhoneSupport geo={geo} />
        )}
      </div>
      {/* )} */}

      {(isXS || isSM || isMD || isLG) && Object.keys(dataQuote).length > 0 && (
        <>
          {geo?.country_id === 164 && whatsapp && geo && (
            <SideBar whatsapp={whatsapp} geo={geo} lang={i18n.language} />
          )}
          <QuoterDetails
            data={dataQuote}
            visibility={toggleDetails}
            breakpoint={currentBreakpoint}
          />
        </>
      )}

      {(isXS || isSM || isMD || isLG) && (
        <QuoterWrapper breakpoint={currentBreakpoint}>
          <QuoterComponent
            className="relative"
            view_mode={"default"}
            config={getConfig("default")}
            quoterData={getData}
          />
        </QuoterWrapper>
      )}

      <div className="flex flex-col items-center justify-center w-full bg-[#EFF1F4] relative">
        <div
          className={
            "section-wrapper w-full " +
            (isXS ? "max-w-[345px] " : "") +
            (isSM ? "max-w-[660px] " : "") +
            (isMD ? "max-w-[768px] " : "") +
            (isLG ? "max-w-[1056px] " : "") +
            (isXL || is2XL ? "pt-24" : "")
          }
        >
          <div className="px-3">
            <BackComponent />

            <Fade in={true}>
              <h1 className="text-blue-primary font-bold text-3xl lg:text-5xl text-center md:mt-16 mt-8 font-titillium max-w-[545px] mx-auto">
                {t("index.title")}
              </h1>
            </Fade>
            <div className="w-full flex flex-col items-center justify-center mt-8 mb-4 md:mb-32 currency-selector">
              {currencies?.list?.length > 0 && (
                <CurrencyComponent
                  currencies={currencies}
                  currentCurrency={currentCurrency}
                  Currency={Currency}
                />
              )}
              {spinner && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "2rem",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </div>
          </div>
          <div
            className={
              "w-full flex items-center justify-center products-cards-wrapper " +
              (isXS || isSM ? "flex-col" : "") +
              (!isSM && !isXS ? "flex-row" : "")
            }
          >
            {products.length > 0 &&
              products.map((product, index) => {
                return (
                  <CardComponent
                    selectProduct={productSelected}
                    gotoCoverages={scrollToCover}
                    key={index}
                    product={product}
                  />
                );
              })}
            {/* {moreProducts.products.length > 0 && moreProductsBtnVisibility &&
              moreProducts.products.map((product, index) => {
                return <CardComponent key={index} product={product} />;
              })} */}
          </div>

          {moreProducts.btn_more_visibility && (
            <MoreProductsBtn seeMoreProducts={seeMoreProducts} />
          )}

          <BannerComponent breakpoint={currentBreakpoint} />

          {comparativeTable.coverages_data.length > 0 && (
            <>
              <div className="w-full mt-16 mb-20 lg:mb-[138px] lg:mt-[25px]">
                <h1 className="text-blue-primary font-bold text-3xl text-center my-8 lg:text-4xl font-inter">
                  {t("index.compareTable")}
                </h1>
              </div>

              <ComparativeTable
                breakpoint={currentBreakpoint}
                coverNav={coverNav}
                comparativeTable={comparativeTable}
                products={products}
                dataTable={dataTable}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
